<template>
  <router-view/>
</template>

<script setup>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import popupViews from "@/interfaces/popupViews";
import { useRoute } from "vue-router";
import { useHead } from "@vueuse/head";

const store = useStore();
const currentRoute = useRoute();

useHead({
  title: computed(() => (currentRoute.meta.title ?? '')),
  meta: computed(() => (currentRoute.meta.meta ?? [])),
  script: computed(() => (currentRoute.meta.script ?? [])),
});

onMounted(() => {
  console.log(currentRoute);
  setTimeout(() => {
    if (currentRoute.query.callback) {
      store.dispatch('openPopup', { view: popupViews.CALLBACK_QUIZ })
    }
  }, 1000)
});

</script>

<style lang="scss">
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-weight: 900;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  font-family: Inter, sans-serif;
  border: 1px solid transparent;
  padding: 10.5px 15px;
  font-size: 18px;
  line-height: 140%;
  border-radius: 4px;
  text-transform: uppercase;
  box-shadow: 0 0 0 0 transparent;
  transition: color .15s ease-in-out,
  background-position .15s,
  background .15s ease-in-out,
  background-color .15s ease-in-out,
  border-color .15s ease-in-out,
  box-shadow .25s ease-in-out,
  bottom .2s,
  top .2s;
}

.btn.cube {
  padding: 7px;
}

.btn.big {
  padding: 16px;
  min-height: 64px;
}

.btn.large {
  border-radius: 8px;
  padding: 24px 40px;
  min-height: 80px;
}

.btn.sm-text {
  font-size: 16px;
  font-weight: 600;
  text-transform: unset;
}

.btn svg {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--currentColor)
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btn.disabled, .btn:disabled {
  opacity: .65;
  pointer-events: none;
}

/* primary */

.btn-primary {
  color: #fff;
  background: linear-gradient(180deg, #3BDDF3 28%, #004E96 75%);
  --currentColor: #fff;
  background-position-y: 55%;
  background-size: 200% 350%;
}

.btn-primary:hover {
  background-position-y: 65%;
}

.btn-primary:active {
  background-position-y: 75%;
}

.btn-primary.focus,
.btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .5);
}

/* primary */

/* secondary */

.btn-secondary {
  color: #fff;
  border: 2px solid rgba(255, 255, 255, 0.30);
  box-shadow: 0 4px 64px 0 rgba(0, 37, 91, 0.25);
  background: linear-gradient(180deg, #61B4CE 28%, #005DB4 75%);
  --currentColor: #fff;
  background-position-y: 55%;
  background-size: 200% 350%;
  position: relative;
  top: 0;
}

.btn-secondary:after {
  content: '';
  position: absolute;
  display: block;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -6px;
  border-radius: 16px;
  background: linear-gradient(180deg, #5075BC -39.85%, #2054B9 131.06%);
  box-shadow: 0 4px 64px 0 rgba(0, 37, 91, 0.25);
  z-index: -1;
  transition: bottom .2s;
}

.btn-secondary:hover {
  background-position-y: 65%;
}

.btn-secondary:active {
  background-position-y: 75%;
  top: 2px;
}

.btn-secondary:active:after {
  bottom: -4px;
}

.btn-secondary.focus,
.btn-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .5);
}

/* secondary */

/* light */

.btn-light {
  color: #003A67;
  --currentColor: #21A1CA;
  background-color: transparent;
  border-color: #E0E0E0;
}

.btn-light:hover {
  background-color: #E0E0E030;
}

.btn-light:active {
  background-color: #E0E0E0;
}

.btn-light.focus,
.btn-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(224, 224, 224, 0.6);
}

/* light */

/* info */

.btn-info {
  color: #21A1CA;
  --currentColor: #21A1CA;
  background-color: transparent;
  border-color: #21A1CA;
}

.btn-info:hover {
  background-color: #21A1CA10;
}

.btn-info:active {
  color: #fff;
  --currentColor: #fff;
  background-color: #21A1CA;
}

.btn-info.focus,
.btn-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(5, 123, 250, 0.5);
}

/* info */

/* presentation */

.btn-presentation {
  color: #fff;
  border: 2px solid rgba(255, 255, 255, 0.30);
  box-shadow: 0 4px 64px 0 rgba(20, 142, 40, 0.25);
  background: linear-gradient(180deg, #61CE6C 28%, #00B412 75%);
  --currentColor: #fff;
  background-position-y: 55%;
  background-size: 200% 350%;
  position: relative;
  top: 0;
}

.btn-presentation.with-image {
  padding-left: 22px;
  padding-right: 108px;

}

.btn-presentation.with-image:before {
  position: absolute;
  content: '';
  background-size: cover;
  background-image: url("@/assets/images/benefits/offer-benefits.png");
  height: 92px;
  width: 100px;
  right: -4px;
  top: -4px;
}

.btn-presentation:after {
  content: '';
  position: absolute;
  display: block;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -6px;
  border-radius: 16px;
  background: linear-gradient(180deg, #61CE6C -39.85%, #00B412 131.08%);
  box-shadow: 0 4px 64px 0 rgba(0, 37, 91, 0.25);
  z-index: -1;
  transition: bottom .2s;
}

.btn-presentation:hover {
  background-position-y: 75%;
}

.btn-presentation:active {
  background-position-y: 98%;
  top: 2px;
}

.btn-presentation:active:after {
  bottom: -4px;
}

.btn-presentation.focus,
.btn-presentation:focus {
  box-shadow: 0 0 0 0.2rem rgba(97, 206, 108, 0.5);
}

/* presentation */

.flex-1 {
  flex: 1;
}

.w-max {
  width: max-content;
}

.w-min {
  width: min-content;
}

.w-100 {
  width: 100%;
}

@media (max-width: 1200px) {
  .btn-presentation.with-image {
    padding-left: 24px;
    padding-right: 24px;
  }

  .btn-presentation.with-image:before {
    content: unset;
  }
}

@media (max-width: 991px) {
  .cube-m {
    padding: 7px;
  }
}

@media (max-width: 767px) {
  .btn.large {
    padding: 24px;
  }
}
</style>
