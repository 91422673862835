import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    name: 'main',
    path: '/',
    component: async () => import('@/views/MainView.vue'),
    meta: {
      name: 'Главная',
      title: 'Бизнес по поверке счетчиков воды с прибылью от 200 000 руб. в месяц',
      meta: [
        { name: 'description', content: 'Готовый бизнес по поверке счетчиков под ключ с доходом от 200000 руб. в месяц. Федеральная программа партнерства от компании рцсм в сфере поверки счетчиков на дому.', },
        { property: 'og:title', content: 'Бизнес по поверке счетчиков воды в вашем регионе с прибылью от 200 000 руб в месяц', },
        { property: "og:type", content: "website" },
        { property: "og:url", content: "https://rcsmpartner.ru/" },
        { property: "og:image", content: "https://rcsmpartner.ru/img/logo.d82dcf85.svg" },
        { property: "og:description", content: "Федеральная программа партнерства от компании рцсм в сфере поверки счетчиков на дому" },
        { property: "og:site_name", content: "rcsmpartner.ru" },
        { property: "og:locale", content: "ru_RU" },
      ],
      script: [
        {
          type: 'application/ld+json',
          tagPosition: "head",
          textContent: () => {
            return JSON.stringify({
              "@context": "http://schema.org",
              "@type": "WebSite",
              "name": "Федеральная программа партнерства от компании рцсм в сфере поверки счетчиков на дому",
              "url": "https://rcsmpartner.ru/",
              "potentialAction": {
                "@type": "SearchAction",
                "target": "https://rcsmpartner.ru/search?q={search_term_string}",
                "query-input": "required name=search_term_string"
              }
            })
          }
        }
      ]
    }
  },
  {
    name: 'add',
    path: '/add',
    component: async () => import('@/views/ServicesDetailView.vue'),
    meta: {
      name: 'Дополнительные блоки'
    }
  },
  {
    name: 'services',
    path: '/services',
    component: async () => import('@/views/ServicesView.vue'),
    meta: {
      name: 'Все услуги',
      title: 'Услуги и возможности партнеров РЦСМ',
      meta: [
        { name: 'description', content: 'Наша компания более 5 лет успешно ведет свою деятельность в сфере оказания услуг по поверке и замене счетчиков горячей и холодной воды.', },
        { property: 'og:title', content: 'Услуги и возможности партнеров РЦСМ', },
      ],
    }
  },
  {
    name: 'services-1',
    path: '/services/1',
    component: async () => import('@/views/services/ElectronicCertificates.vue'),
    meta: {
      name: 'Электронные протоколы',
      title: 'Электронные протоколы о поверке - компания РЦСМ',
      meta: [
        { name: 'description', content: 'Наша компания более 5 лет успешно ведет свою деятельность в сфере оказания услуг по поверке и замене счетчиков горячей и холодной воды. При этом, компания РЦСМ является производителем счетчиков воды и поверочных установок.', },
        { property: 'og:title', content: 'Электронные свидетельства о поверке - компания РЦСМ', },
      ],
      script: [

        {
          type: 'application/ld+json',
          tagPosition: 'head',
          textContent: () => {
            return JSON.stringify({
              "@context": "http://schema.org",
              "@type": "Service",
              "name": "Электронные свидетельства",
              "description": "Компания РЦСМ перешла на электронные свидетельства о поверке. Использовать электронные свидетельства -  удобно, надежно и ускоряет процесс вашей работы",
              "serviceType": "Электронные свидетельства",
              "areaServed": "RU",
              "itemListElement": [
                {
                  "@type": "Offer",
                  "name": "Электронные свидетельства",
                }
              ]
            })
          }
        }
      ]
    }
  },
  {
    name: 'services-2',
    path: '/services/2',
    component: async () => import('@/views/services/VerificationInstallation.vue'),
    meta: {
      name: 'Поверочная установка',
      title: 'Проверки без снятия приборов учета воды - компания РЦСМ',
      meta: [
        { name: 'description', content: 'Наша компания предоставляет своим партнерам возможность выгодно приобрести поверочную установку для осуществления процедуры поверки счетчиков горячей и холодной воды', },
        { property: 'og:title', content: 'Проверки без снятия приборов учета воды - компания РЦСМ', },
      ],
      script: [

        {
          type: 'application/ld+json',
          tagPosition: 'head',
          textContent: () => {
            return JSON.stringify({
              "@context": "http://schema.org",
              "@type": "Service",
              "name": "Поверочная установка",
              "description": "Компанией РЦСМ налажено собственное производство поверочных установок, позволяющих проводить процедуру поверки без снятия приборов учета воды",
              "serviceType": "Поверочная установка",
              "areaServed": "RU",
              "itemListElement": [
                {
                  "@type": "Offer",
                  "name": "Поверочная установка",
                }
              ]
            });
          }
        }
      ]
    }
  },
  {
    name: 'services-3',
    path: '/services/3',
    component: async () => import('@/views/services/AdditionalInstruments.vue'),
    meta: {
      name: 'Дополнительные средства измерений',
      title: 'Дополнительные средства измерений - компания РЦСМ',
      meta: [
        { name: 'description', content: 'Полный набор дополнительного оборудования необходим для проведения поверки счетчиков воды (Рекомендация ГСИ, Счетчики воды, Методика поверки)', },
        { property: 'og:title', content: 'Дополнительные средства измерений - компания РЦСМ', },
      ],
      script: [

        {
          type: 'application/ld+json',
          tagPosition: 'head',
          textContent: () => {
            return JSON.stringify({
              "@context": "http://schema.org",
              "@type": "Service",
              "name": "Дополнительные средства измерений",
              "description": "Для увеличения количества поверок в день до 25 вы можете приобрести оптосчитыватель. Это сделает вашу деятельность более прибыльной. Наличие дополнительных приборов является обязательным условием для соответствия процедуры нормативам и методикам поверки.",
              "serviceType": "Дополнительные средства измерений",
              "areaServed": "RU",
              "itemListElement": [
                {
                  "@type": "Offer",
                  "name": "Дополнительные средства измерений",
                }
              ]
            });
          }
        }
      ]
    }
  },
  {
    name: 'services-4',
    path: '/services/4',
    component: async () => import('@/views/services/AuditSystem.vue'),
    meta: {
      name: 'Система учета проверок',
      title: 'Система учета поверок - компания РЦСМ',
      meta: [
        { name: 'description', content: 'Специально для компании РЦСМ была разработана система взаимодействия с партнерами и клиентами, а также загрузки отчетов о поверках (CRM).', },
        { property: 'og:title', content: 'Система учета поверок - компания РЦСМ', },
      ],
      script: [
        {
          type: 'application/ld+json',
          tagPosition: 'head',
          textContent: () => {
            return JSON.stringify({
              "@context": "http://schema.org",
              "@type": "Service",
              "name": "Система учета поверок",
              "description": "Данная система учитывает основные возможности, которые необходимы в работе метролога, все что необходимо в работе есть в ее функционале. При этом мы продолжаем ее совершествовать, чтобы сделать вашу работу еще быстрее и удобнее.",
              "serviceType": "Система учета поверок",
              "areaServed": "RU",
              "itemListElement": [
                {
                  "@type": "Offer",
                  "name": "Система учета поверок",
                }
              ]
            });
          }
        }
      ]
    }
  },
  {
    name: 'services-5',
    path: '/services/5',
    component: async () => import('@/views/services/EducationMaterials.vue'),
    meta: {
      name: 'Обучающие материалы',
      title: 'Обучающие материалы - компания РЦСМ',
      meta: [
        { name: 'description', content: 'Доступ к обучающим материалам получают все партнеры компании РЦСМ. Вы в любой момент сможете обратиться к ним, если в вашей работе возникнут какие-то трудности', },
        { property: 'og:title', content: 'Обучающие материалы - компания РЦСМ', },
      ],
      script: [
       
        {
          type: 'application/ld+json',
          tagPosition: 'head',
          textContent: () => {
            return JSON.stringify({
              "@context": "http://schema.org",
              "@type": "Service",
              "name": "Обучающие материалы",
              "description": "Став нашим партнером вы получаете доступ к обучающим видеоматериалам, которые будут незаменимы в процессе работы.",
              "serviceType": "Обучающие материалы",
              "areaServed": "RU",
              "itemListElement": [
                {
                  "@type": "Offer",
                  "name": "Обучающие материалы",
                }
              ]
            });
          }
        }
      ]
    }
  },
  {
    name: 'services-6',
    path: '/services/6',
    component: async () => import('@/views/services/MarketingMaterials.vue'),
    meta: {
      name: 'Рекламные материалы',
      title: 'Рекламные материалы - компания РЦСМ',
      meta: [
        { name: 'description', content: 'Каждый партнер в подарок от компании РЦСМ получает набор шаблонов рекламных материалов, чтобы старт их работы и получение первых заявок был как можно более оперативным', },
        { property: 'og:title', content: 'Рекламные материалы - компания РЦСМ', },
      ],
      script: [

        {
          type: 'application/ld+json',
          tagPosition: 'head',
          textContent: () => {
            return JSON.stringify({
              "@context": "http://schema.org",
              "@type": "Service",
              "name": "Рекламные материалы",
              "description": "Каждый партнер в подарок от компании РЦСМ получает набор шаблонов рекламных материалов, чтобы старт их работы и получение первых заявок был как можно более оперативным",
              "serviceType": "Рекламные материалы",
              "areaServed": "RU",
              "itemListElement": [
                {
                  "@type": "Offer",
                  "name": "Рекламные материалы",
                }
              ]
            })
          }
        }
      ]
    },
  },
  {
    name: 'services-7',
    path: '/services/7',
    component: async () => import('@/views/services/CreateSitePartner.vue'),
    meta: {
      name: 'Разработка сайта для партнеров',
      title: 'Рекламный сайт для партнеров - компания РЦСМ',
      meta: [
        { name: 'description', content: 'Наличие сайта является обязательным уловием для ведения любой предпринимательской деятельности. Получите доступ к разработанному для наших партнеров сайту, с помощью которого вы сможете получать заявки на поверку из интернета', },
        { property: 'og:title', content: 'Рекламный сайт для партнеров - компания РЦСМ', },
      ],
      script: [
        
        {
          type: 'application/ld+json',
          tagPosition: 'head',
          textContent: () => {
            return JSON.stringify({
              "@context": "http://schema.org",
              "@type": "Service",
              "name": "Рекламный сайт для партнеров",
              "description": "Наличие сайта является обязательным условием для ведения любой предпринимательской деятельности. Получите доступ к разработанному для наших партнеров сайту, с помощью которого вы сможете получать заявки на поверку из интернета",
              "serviceType": "Рекламный сайт для партнеров",
              "areaServed": "RU",
              "itemListElement": [
                {
                  "@type": "Offer",
                  "name": "Рекламный сайт для партнеров",
                }
              ]
            });
          }
        }
      ]
    },
  },
  {
    name: 'services-8',
    path: '/services/8',
    component: async () => import('@/views/services/MarketingSettingDeploy.vue'),
    meta: {
      name: 'Настройка рекламы на сайт',
      title: 'Настройка рекламы на сайт партнеров - компания РЦСМ',
      meta: [
        { name: 'description', content: 'Закажите или получите услугу настройки рекламы на сайт, который компания РЦСМ готова вам предоставить для продвижения ваших услуг в вашем регионе.', },
        { property: 'og:title', content: 'Настройка рекламы на сайт партнеров - компания РЦСМ', },
      ],
      script: [
        
        {
          type: 'application/ld+json',
          tagPosition: 'head',
          textContent: () => {
            return JSON.stringify({
              "@context": "http://schema.org",
              "@type": "Service",
              "name": "Настройка рекламы на сайт",
              "description": "Закажите или получите услугу настройки рекламы на сайт, который компания РЦСМ готова вам предоставить для продвижения ваших услуг в вашем регионе.",
              "serviceType": "Настройка рекламы на сайт",
              "areaServed": "RU",
              "itemListElement": [
                {
                  "@type": "Offer",
                  "name": "Настройка рекламы на сайт",
                }
              ]
            })
          }
        },
      ],
    },
  },
  {
    name: 'services-9',
    path: '/services/9',
    component: async () => import('@/views/services/CounterArshan.vue'),
    meta: {
      name: 'Счетчики воды “Аршин”',
      title: 'Счетчики воды “Аршин” - компания РЦСМ',
      meta: [
        { name: 'description', content: 'Компания РЦСМ наладила производство собственной линейки счетчиков горячей и холодной воды. Своим партнерам мы готовы предоставить счетчики по наиболее выгодным ценам', },
        { property: 'og:title', content: 'Счетчики воды “Аршин” - компания РЦСМ', },
      ],
      script: [
    
        {
          type: 'application/ld+json',
          tagPosition: 'head',
          textContent: () => {
            return JSON.stringify({
              "@context": "http://schema.org",
              "@type": "Service",
              "name": "Счетчики воды Аршин",
              "description": "Компания РЦСМ наладила производство собственной линейки счетчиков горячей и холодной воды. Своим партнерам мы готовы предоставить счетчики по наиболее выгодным ценам",
              "serviceType": "Счетчики воды Аршин",
              "areaServed": "RU",
              "itemListElement": [
                {
                  "@type": "Offer",
                  "name": "Счетчики воды Аршин",
                }
              ]
            });
          }
        }
      ]
    },
  },
  {
    name: 'about',
    path: '/about_company',
    component: async () => import('@/views/AboutCompanyView.vue'),
    meta: {
      name: 'О компании',
      title: 'Бизнес по поверке счетчиков воды с прибылью от 200 000 руб. в месяц',
      meta: [
        { name: 'description', content: 'С нашей помощью вы сможете легко начать свой бизнес по поверке счетчиков горячей и холодной воды без проведения их демонтажа.', },
        { property: 'og:title', content: 'Бизнес по поверке счетчиков воды с прибылью от 200 000 руб. в месяц', },
      ],
    },
  },
  {
    name: 'documents',
    path: '/about_company/documents',
    component: async () => import('@/views/company/DocumentsView.vue'),
    meta: {
      name: 'Документы',
      title: 'Документы'
    }
  },
  {
    name: 'contacts',
    path: '/about_company/contacts',
    component: async () => import('@/views/company/ContactsView.vue'),
    meta: {
      name: 'Контакты',
      title: 'Наши контакты - компания РЦСМ',
      meta: [
        { name: 'description', content: 'Всем партнерам и желающим сотрудничать с компанией РЦСМ по вопросам бизнеса по поверке счетчиков воды всегда доступна связь с нашим менеджером по всем интересующим вопросам о бизнесе, работе и помощи', },
        { property: 'og:title', content: 'Наши контакты - компания РЦСМ', },
      ],
    }
  },
  {
    name: 'tariffs',
    path: '/tariffs',
    component: async () => import('@/views/TariffsView.vue'),
    meta: {
      name: 'Пакеты услуг',
      title: 'Помощь по настройке бизнеса по поверке счетчиков воды',
      meta: [
        { name: 'description', content: 'Для удобного запуска вашего бизнеса по поверке счетчиков мы собрали все необходимое для работы в пакеты услуг', },
        { property: 'og:title', content: 'Помощь по настройке бизнеса по поверке счетчиков воды', },
      ],
    }
  },
  {
    name: 'tariff-start',
    path: '/tariffs/start',
    component: async () => import('@/views/tariffs/StartTariff.vue'),
    meta: {
      name: 'Пакет “Стартовый”',
      title: 'Бизнес по поверке счетчиков воды - пакет “Стартовый”',
      meta: [
        { name: 'description', content: 'В данном тарифе собран минимальный набор услуг, который нужен для быстрого запуска бизнеса по поверке счетчиков на дому. При этом его стоимость отлично подойдет начинающему предпринимателю.', },
        { property: 'og:title', content: 'Помощь по настройке бизнеса по поверке счетчиков воды', },
      ],
      script: [
    
        {
          type: 'application/ld+json',
          tagPosition: 'head',
          textContent: () => {
            return JSON.stringify({
              "@context": "http://schema.org",
              "@type": "Service",
              "name": "Бизнес по поверке счетчиков воды - пакет “Стартовый”",
              "description": "В данном тарифе собран минимальный набор услуг, который нужен для быстрого запуска бизнеса по поверке счетчиков на дому. При этом его стоимость отлично подойдет начинающему предпринимателю.",
              "serviceType": "Пакет “Стартовый",
              "areaServed": "RU",
              "itemListElement": [
                {
                  "@type": "Offer",
                  "name": "Пакет “Стартовый”",
                  "price": "148.000р"
                }
              ]
            });
          }
        }
      ]
    }
  },
  {
    name: 'tariff-optimal',
    path: '/tariffs/optimal',
    component: async () => import('@/views/tariffs/OptimalTariff.vue'),
    meta: {
      name: 'Оптимальный',
      title: 'Бизнес по поверке счетчиков воды - пакет “Оптимальный”',
      meta: [
        { name: 'description', content: 'В тарифе “Оптимальный” мы объединили все необходимое для того, чтобы полноценно и эффективно запустить новый прибыльный бизнес по поверке счетчиков воды в вашем регионе.', },
        { property: 'og:title', content: 'Помощь по настройке бизнеса по поверке счетчиков воды', },
      ],
      script: [
    
        {
          type: 'application/ld+json',
          tagPosition: 'head',
          textContent: () => {
            return JSON.stringify({
              "@context": "http://schema.org",
              "@type": "Service",
              "name": "Бизнес по поверке счетчиков воды - пакет “Оптимальный”",
              "description": "В тарифе “Оптимальный” мы объединили все необходимое для того, чтобы полноценно и эффективно запустить новый прибыльный бизнес по поверке счетчиков воды в вашем регионе.",
              "serviceType": "Пакет “Оптимальный”",
              "areaServed": "RU",
              "itemListElement": [
                {
                  "@type": "Offer",
                  "name": "Пакет “Оптимальный”",
                  "price": "358.000р"
                }
              ]
            });
          }
        }
      ]
    }
  },
  {
    name: 'tariff-complex',
    path: '/tariffs/complex',
    component: async () => import('@/views/tariffs/ComplexTariff.vue'),
    meta: {
      name: 'Комплексный',
      title: 'Бизнес по поверке счетчиков воды - пакет “Комплексный”',
      meta: [
        { name: 'description', content: 'Пакет услуг “Комплексный” включает в себя полный набор всего необходимого для старта бизнеса по поверке счетчиков со значительным доходом, а также весь опыт работы компании РЦСМ за 5 лет работы с партнерами по всей РФ', },
        { property: 'og:title', content: 'Помощь по настройке бизнеса по поверке счетчиков воды', },
      ],
      script: [
    
        {
          type: 'application/ld+json',
          tagPosition: 'head',
          textContent: () => {
            return JSON.stringify({
              "@context": "http://schema.org",
              "@type": "Service",
              "name": "Бизнес по поверке счетчиков воды - пакет “Комплексный”",
              "description": "Пакет услуг “Комплексный” включает в себя полный набор всего необходимого для старта бизнеса по поверке счетчиков со значительным доходом, а также весь опыт работы компании РЦСМ за 5 лет работы с партнерами по всей РФ",
              "serviceType": "Бизнес по поверке счетчиков воды - пакет “Комплексный”",
              "areaServed": "RU",
              "itemListElement": [
                {
                  "@type": "Offer",
                  "name": "Пакет “Комплексный”",
                  "price": "728.000р"
                }
              ]
            });
          }
        }
      ]
    }
  },
  {
    name: 'conditions',
    path: '/conditions',
    component: async () => import('@/views/ConditionsView.vue'),
    meta: {
      name: 'Условия',
      title: 'Условия партнерской программы RCSM',
      meta: [
        { name: 'description', content: 'Займитесь понятным и прибыльным бизнесом, который не требует больших вложений: проводите поверку счетчиков без демонтажа в вашем городе в качестве нашего партнера.', },
        { property: 'og:title', content: 'Условия партнерской программы RCSM', },
      ],
    }
  },
  {
    name: 'not-found',
    path: '/:catchAll(.*)',
    component: async () => import('@/views/NotFound.vue'),
    meta: {
      name: '404 - страница не найдена'
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach(() => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
})

export default router;