import { createStore } from "vuex";
import axios from "axios";

export default createStore({
  state: {
    popup: {
      open: false,
      title: '',
      view: '',
    }
  },
  getters: {
    showPopup(state) {
      return state.popup.open;
    },
    popupTitle(state) {
      return state.popup.title;
    },
    popupView(state) {
      return state.popup.view;
    },
  },
  mutations: {
    openPopup(state, { view, title }) {
      state.popup.view = view;
      state.popup.open = true;
      state.popup.title = title;
      document.body.classList.add('lock');
    },
    closePopup(state) {
      state.popup.view = '';
      state.popup.open = false;
      state.popup.title = '';
      document.body.classList.remove('lock');
    }
  },
  actions: {
    openPopup({ commit }, { view, title = '' }) {
      commit('openPopup', { view, title })
    },
    closePopup({ commit }) {
      commit('closePopup');
    },
    async sendToTg(store, data) {
      try {
        const url = `${window.location.origin}/api/tg/`;
        await axios.post(url, data);
      } catch (e) {
        console.log(e);
      }
    }
  }
})